import { MinusCircleOutlined, PlusOutlined, DeleteOutlined, UserAddOutlined } from "@ant-design/icons";
import { useQuery } from '@tanstack/react-query'
import {
  Button,
  Col,
  Divider,
  Form,
  notification,
  Select,
  Typography,
  Card,
  Avatar,
  Modal,
  Input,
  Space,
  Tooltip,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import api from "~/services/axios";
import { queryClient } from "~/services/queryClient";
import useContextStore from "~/stores/useContextStore";
import { Contact } from "~/types/contact";
import ContactForm from './ContactForm';
import dayjs from 'dayjs';

interface ProfileProps {
  id: string;
}

const { Search } = Input;

const Profile = ({ id }: ProfileProps) => {
  const [form] = Form.useForm();
  const [selectKinshipForm] = Form.useForm();
  const kinshipRef = useRef<typeof Select>(null);
  const { currentProfile, setCurrentProfile } = useContextStore();
  const [searchModalVisible, setSearchModalVisible] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [contactForm] = Form.useForm();
  const [selectedContact, setSelectedContact] = useState<Contact | null>(null);
  const [mode, setMode] = useState<'create' | 'search'>('create');
  const [kinshipSelected, setKinshipSelected] = useState<string | null>(null);
  const { confirm } = Modal;

  const { Text, Title } = Typography;

  const { data: student, isLoading: isLoadingStudent } = useQuery(
    ["student", id],
    () =>
      api.get(`/api/students/${id}`).then((res) => {
        return res.data;
      }),
    {
      // enabled: currentProfile === 'admin',
    },
  );

  const { data: grades, isLoading: isLoadingGrades } = useQuery(
    ["grades"],
    () =>
      api.get(`/api/grades`).then((res) => {
        // remove the 'Plantão', 'Tutoria' and 'Grupo' from grade
        return res.data.filter((grade) => {
          return (
            grade.type !== "Plantão" &&
            grade.type !== "Tutoria" &&
            grade.type !== "Grupo 2h30"
          );
        });
      }),
    {},
  );

  const { data: kinship, isLoading: isLoadingKinship } = useQuery(
    ["kinship"],
    () => api.get(`/api/filters/kinships`).then((res) => res.data),
    {
      staleTime: 1000 * 60 * 60, // 1 hora
    }
  );

  const { data: contacts, isLoading: isLoadingContacts } = useQuery(
    ["contacts", searchValue],
    () => api.get(`/api/contacts?search=${searchValue}`).then((res) => res.data),
    {
      enabled: searchModalVisible && mode === 'search' && searchValue !== '',
      keepPreviousData: true,

    }
  );

  useEffect(() => {
    if (student) {
      const { name, email, phone, address, grades, ...rest } = student;

      form.setFieldsValue({
        grades: grades.map((grade) => {
          return {
            year: grade.year,
            type: grade.type,
          };
        }),
      });
    }
  }, [student]);

  const onFinish = () => {
    api
      .post(`/api/students/${id}/grades`, {
        grades: form.getFieldsValue().grades,
      })
      .then((res) => {
        notification.success({
          message: "Histórico atualizado com sucesso!",
          description: "As informações foram atualizadas com sucesso",
        });

        queryClient.invalidateQueries(["student", id]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDetachContact = async (contactId: string) => {
    confirm({
      title: 'Tem certeza que deseja remover este contato?',
      onCancel: () => {
        setKinshipSelected('')
        setSearchModalVisible(false)
        setSelectedContact(null)
        setMode('create')
        contactForm.resetFields()
      },
      onOk: async () => {
        try {
          await api.post(`/api/contacts/${contactId}/detach-student`, {
            student_id: id,
          });

          notification.success({
            message: "Contato removido com sucesso!",
          });

          queryClient.invalidateQueries(["student", id]);
          setSearchModalVisible(false)
          setSelectedContact(null)
          setSearchValue('')
          contactForm.resetFields()
        } catch (error) {
          notification.error({
            message: "Erro ao remover contato",
            description: "Não foi possível remover o contato do aluno",
          });
        }
      }
    });
  };

  const handleAttachContact = async (contactId: string, kinshipId: string) => {
    confirm({
      title: 'Tem certeza que deseja vincular este contato?',
      onCancel: (close) => {
        selectKinshipForm.resetFields()
        close()
      },
      onOk: async () => {
        try {
          await api.post(`/api/contacts/${contactId}/attach-student`, {
            student_id: id,
            kinship_id: kinshipId
          });

          notification.success({
            message: "Contato vinculado com sucesso!",
          });

          queryClient.invalidateQueries(["student", id]);
          setSearchModalVisible(false);
          Modal.destroyAll()
          contactForm.resetFields()
        } catch (error) {
          notification.error({
            message: "Erro ao vincular contato",
            description: "Não foi possível vincular o contato ao aluno",
          });
        }
      }
    });
  };

  const handleCreateContact = async () => {
    try {
      const values = await contactForm.validateFields();

      const response = await api.post('/api/contacts', {
        ...values,
        birth_date: values.birth_date?.format('YYYY-MM-DD'),
        student_id: id,
      });

      notification.success({
        message: "Contato criado com sucesso!",
      });

      queryClient.invalidateQueries(["student", id]);
      setSearchModalVisible(false);
      contactForm.resetFields();
    } catch (error) {
      if (error.errorFields) {
        return; // Erro de validação do form
      }
      notification.error({
        message: "Erro ao criar contato",
        description: "Não foi possível criar o contato",
      });
    }
  };

  const handleUpdateContact = async () => {
    try {
      const values = await contactForm.validateFields();

      await api.put(`/api/contacts/${selectedContact?.id}`, {
        ...values,
        birth_date: values.birth_date?.format('YYYY-MM-DD'),
      });

      notification.success({
        message: "Contato atualizado com sucesso!",
      });

      queryClient.invalidateQueries(["student", id]);
      queryClient.invalidateQueries(["contacts"]);
      setSelectedContact(null);
      contactForm.resetFields();
      setMode('create');
      setSearchModalVisible(false);
    } catch (error) {
      if (error.errorFields) {
        return; // Erro de validação do form
      }
      notification.error({
        message: "Erro ao atualizar contato",
        description: "Não foi possível atualizar o contato",
      });
    }
  };

  const handleEditContact = (contact: Contact) => {
    setMode('create');
    setSelectedContact(contact);
    contactForm.setFieldsValue({
      ...contact,
      birth_date: contact.birth_date ? dayjs(contact.birth_date) : undefined,
    });
  };

  const handleModalClose = () => {
    //close the modal
    setSearchModalVisible(false);
    setSelectedContact(null);
    setMode('create');
    contactForm.resetFields();
  };

  const handleModalCancel = () => {
    console.log(mode);
    if (mode === 'create' && !selectedContact) {
      console.log('create and no selected contact');
      setMode('search');
      contactForm.resetFields();
    } else if (mode === 'create' && selectedContact) {
      console.log('create and selected contact');
      setMode('search');
      setSelectedContact(null);
      contactForm.resetFields();
    } else {
      setSearchModalVisible(false);
      setSelectedContact(null);
      setMode('create');
      contactForm.resetFields();
    }
  };

  const handleOpenModal = (initialMode: 'create' | 'search' = 'create') => {
    setMode(initialMode);
    setSearchModalVisible(true);
    setSelectedContact(null);
    contactForm.resetFields();
  };

  return (
    <Col span={24} style={{ maxWidth: "990px", margin: "0 auto" }}>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        requiredMark={true}
      >
        {/* Contacts Section */}
        <Divider orientation="left" >
          <Space>
            Contatos
            <Button.Group>
              <Button
                type="primary"
                icon={<UserAddOutlined />}
                onClick={() => handleOpenModal('search')}
              >
                Adicionar Contato
              </Button>
            </Button.Group>
          </Space>
        </Divider>

        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))', gap: '16px' }}>
          {student?.contacts?.map((contact: Contact) => (
            <Card
              key={contact.id}
              size="small"
              title={contact.name}
              extra={
                <Tooltip title="Remover contato">
                  <Button
                    type="text"
                    danger
                    icon={<DeleteOutlined />}
                    onClick={() => handleDetachContact(contact.id)}
                  />
                </Tooltip>
              }
            >
              <p><strong>Parentesco:</strong> {contact.students?.find(s => s.id === id)?.kinship.name}</p>
              {contact.profession && <p><strong>Profissão:</strong> {contact.profession}</p>}
              <p><strong>Telefone:</strong> {contact.phone}</p>
              {contact.birth_date && (
                <p><strong>Data de Nascimento:</strong> {new Date(contact.birth_date).toLocaleDateString()}</p>
              )}

              {contact.students?.length > 0 && (
                <>
                  <Divider style={{ margin: '12px 0' }}>Alunos vinculados</Divider>
                  <div style={{ display: 'flex', flexWrap: 'wrap', gap: '8px' }}>
                    {contact.students.map(student => (
                      <Tooltip key={student.id} 
                      title={`${student.kinship.name} do(a) ${student.name}`}
                      >
                        <Avatar src={student.picture}>
                          {!student.picture && student.name[0]}
                        </Avatar>
                      </Tooltip>
                    ))}
                  </div>
                </>
              )}
            </Card>
          ))}
        </div>

        {/* Search/Create Modal */}
        <Modal
          title={selectedContact ? "Editar Contato" : mode === 'create' ? "Novo Contato" : "Buscar Contato"}
          open={searchModalVisible}
          onCancel={handleModalClose}
          onOk={handleModalClose}
          footer={mode === 'search' && !selectedContact ? null : [
            <Button key="cancel" onClick={handleModalCancel}>
              Cancelar
            </Button>,
            <Button
              key="submit"
              type="primary"
              onClick={selectedContact ? handleUpdateContact : handleCreateContact}
            >
              {selectedContact ? "Atualizar" : "Criar"}
            </Button>
          ]}
        >
          {mode === 'create' || selectedContact ? (
            <ContactForm
              form={contactForm}
              initialValues={selectedContact}
              mode={mode}
            />
          ) : (
            <Space direction="vertical" style={{ width: '100%' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '8px' }}>
                <Search
                  placeholder="Buscar por nome, telefone ou profissão"
                  onSearch={value => setSearchValue(value)}
                  style={{ flex: 1 }}
                  enterButton
                  loading={isLoadingContacts && searchValue !== ''}
                />
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={() => setMode('create')}>Criar Contato</Button>

              </div>

              <div style={{ marginTop: '16px' }}>
                {isLoadingContacts && searchValue !== '' ? (
                  <div style={{ textAlign: 'center', padding: '20px' }}>
                    Carregando...
                  </div>
                ) : contacts?.data?.length === 0 || searchValue === '' ? (
                  <div style={{ textAlign: 'center', padding: '20px' }}>
                    {searchValue === '' ? 
                    'Busque por nome, telefone ou profissão para encontrar um contato existente ou clique em criar para adicionar um novo contato'
                     : 'Nenhum contato encontrado com este nome'
                     }
                    
                  </div>
                ) : (
                  contacts?.data?.map((contact: Contact) => (
                    <Card
                      key={contact.id}
                      size="small"
                      style={{ marginBottom: '8px' }}
                      actions={[
                        <Button
                          onClick={() => handleEditContact(contact)}
                        >
                          Editar
                        </Button>,
                        contact.students?.find(s => s.id === id) ? (
                          <Button
                            onClick={() => handleDetachContact(contact.id)}
                          >
                            Remover
                          </Button>
                        ) : (
                          <Select
                            key={`select-${contact.id}`}
                            allowClear
                            placeholder="Selecione um parentesco para vincular"
                            style={{ width: '94%' }}
                            options={kinship}
                            onChange={(value) => {
                              if (value) {
                                handleAttachContact(contact.id, value);
                              }
                            }}
                          />
                        )
                      ]}
                    >
                      <Card.Meta
                        title={contact.name}
                        description={
                          <>
                            <p>{contact?.email || 'Não possui email'} • {contact?.phone}</p>
                            {contact.profession && <p>{contact.profession}</p>}
                            {contact.students?.length > 0 && (
                              <div style={{ marginTop: '8px' }}>
                                <small>Vinculado a:</small>
                                <Avatar.Group max={{ count: 3 }} style={{ marginTop: '4px' }}>
                                  {contact.students.map(student => (
                                    <Tooltip key={student.id}
                                      title={`${student.kinship.name} do(a) ${student.name} `}
                                    >
                                      <Avatar src={student.picture} alt={student.name}>
                                        {!student.picture && student.name[0]}
                                      </Avatar>
                                    </Tooltip>
                                  ))}
                                </Avatar.Group>
                              </div>
                            )}
                          </>
                        }
                      />
                    </Card>
                  ))
                )}
              </div>
            </Space>
          )}
        </Modal>

        {/*  History */}
        <Divider orientation="left">Histórico</Divider>

        <Form.List name="grades">
          {(fields, { add, remove }, { errors }) => (
            <>
              {fields.map((field, index) => (
                <div key={field.key} style={{ display: "flex", width: "100%" }}>
                  <Form.Item
                    validateTrigger={["onChange", "onBlur", "onClick"]}
                    rules={[
                      {
                        required: true,
                        message: "Por favor, insira o ano",
                      },
                    ]}
                    name={[index, "year"]}
                    style={{
                      width: fields.length > 1 ? "calc(49% - 16px)" : "49%",
                      marginRight: "2%",
                    }}
                  >
                    <Select
                      placeholder="Ano letivo"
                      options={new Array(20).fill(0).map((_, i) => {
                        return {
                          label: `${new Date().getFullYear() - (i - 1)}`,
                          value: Number(
                            `${new Date().getFullYear() - (i - 1)}`,
                          ),
                        };
                      })}
                    />
                  </Form.Item>
                  <Form.Item
                    validateTrigger={["onChange", "onBlur"]}
                    rules={[
                      {
                        required: true,
                        message: "Por favor, selecione uma turma",
                      },
                    ]}
                    name={[index, "type"]}
                    style={{
                      width: fields.length > 1 ? "calc(49% - 16px)" : "49%",
                    }}
                  >
                    <Select
                      placeholder="Ano letivo"
                      options={grades?.map((grade) => {
                        return {
                          label: grade.type,
                          value: grade.type,
                        };
                      })}
                    />
                  </Form.Item>
                  {fields.length > 1 ? (
                    <MinusCircleOutlined
                      style={{ margin: "0 8px", marginBottom: "24px" }}
                      className="dynamic-delete-button"
                      onClick={() => remove(field.name)}
                    />
                  ) : null}
                </div>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  style={{ width: "100%" }}
                  icon={<PlusOutlined />}
                >
                  Adicionar novo ano
                </Button>
                <Form.ErrorList errors={errors} />
              </Form.Item>
            </>
          )}
        </Form.List>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Atualizar histórico
          </Button>
        </Form.Item>
      </Form>
    </Col>
  );
};

export default Profile;
