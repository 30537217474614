import { Form, Input, DatePicker, Select } from 'antd';
import { useQuery } from '@tanstack/react-query';
import api from "~/services/axios";
import { Contact } from '~/types/contact';
import dayjs from 'dayjs';
import React from 'react';

interface ContactFormProps {
  initialValues?: Contact;
  form: any;
  mode: 'create' | 'update';
}

const ContactForm = ({ initialValues, form, mode }: ContactFormProps) => {

  const { data: kinships } = useQuery(
    ['kinships'],
    () => api.get('/api/filters/kinships').then(res => res.data),
    {
      staleTime: 1000 * 60 * 60, // 1 hora
    }
  );

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={initialValues ? {
        ...initialValues,
        birth_date: initialValues.birth_date ? dayjs(initialValues.birth_date) : undefined,
      } : undefined}
    >
      <Form.Item
        name="name"
        label="Nome"
        rules={[{ required: true, message: 'Por favor, insira o nome' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        hidden={mode === 'create'}
        name="kinship_id"
        label="Grau de Parentesco"
        rules={[{ required: true, message: 'Por favor, selecione o grau de parentesco' }]}
      >
        <Select
          options={kinships || []}
        />
      </Form.Item>

      <Form.Item
        name="phone"
        label="Telefone"
        
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="profession"
        label="Profissão"
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="birth_date"
        label="Data de Nascimento"
      >
        <DatePicker style={{ width: '100%' }} format="DD/MM/YYYY" />
      </Form.Item>
    </Form>
  );
};

export default ContactForm; 